<script>
import Datepicker from "vue3-datepicker";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { reqMethods } from '../../../state/helpers';
export default {
    components: {
        Layout,
        PageHeader,
        Datepicker
    },
    data() {
        return {
            alertData: null,
          loading: true,
            title: "Historique des interventions",
            items: [
                {
                    text: "Dashboard",
                    href: "/"
                },
                {
                    text: "Interventions",
                    active: true
                }
            ],
            interventions: {},
            interventionData: {},
            reqError: null,
        };
    },
    mounted() {
        this.getIntervList();
    },
    methods: {
        ...reqMethods,
        getIntervList() {
            const that = this;
          this.loading = true;
            this.getRequest('/intervention/intervention/')
                .then(
                    function (response) {
                        that.interventions = response.data;
                        that.interventionData = response.data;
                        console.log(that.interventions);
                    },
                    function (error) {
                        that.reqError = error;
                    }
                )  .finally(() => {
              that.loading = false;
            });
        },
        searchFilter(e) {
            const searchStr = e.target.value;
            this.interventionData = this.interventions.filter((alert) => {
                return (
                    alert.author.toLowerCase().includes(searchStr.toLowerCase()) ||
                    alert.titre.toLowerCase().includes(searchStr.toLowerCase()) ||
                    alert.thematique.toLowerCase().includes(searchStr.toLowerCase()) ||
                    alert.zone.toLowerCase().includes(searchStr.toLowerCase())
                );
            });
        },

    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <div class="row justify-content-between">
                        <div class="col-md-5">
                            <div class="form-group mt-3 mb-0">
                                <div class="search-box me-2">
                                    <div class="position-relative">
                                        <input type="text" class="form-control bg-soft bg-info border-0"
                                            placeholder="Rechercher..." @input="searchFilter($event)" />
                                        <i class="bx bx-search-alt search-icon"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row col-md-6">
                            <div class="form-group col-md-4 mt-3 mb-0">
                                <datepicker v-model="picked" :first-day-of-week="1" lang="en" confirm class="form-control"
                                    placeholder="Date de début"></datepicker>
                            </div>
                            <div class="mt-3 col-md-4 mb-0">
                                <button class="btn btn-info">
                                    <i class="bx bx-slider align-middle me-1"></i>
                                    Lancer le filtre
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive mt-3"  v-if="!loading">
                        <table class="table table-nowrap table-centered mb-0 align-middle">
                            <thead class="table-light">
                                <tr>
                                    <th scope="col">Titre</th>
                                    <th>Agent affecté</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Statut</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="inter in interventionData" :key=inter.id>
                                    <td> {{ inter.title }}</td>
                                    <td> {{ inter.agent?.username }} </td>
                                    <td> {{ inter.created_at.split('T')[0] }} </td>
                                    <td>
                                        <span v-if="inter.status == 1" class="badge font-size-11 m-1 bg-primary">
                                            En cours
                                        </span>
                                         <span v-if="inter.status == 3" class="badge font-size-11 m-1 bg-danger">
                                            En attente
                                        </span>
                                        <span v-if="inter.status == 2" class="badge font-size-11 m-1 bg-danger">
                                            En cours
                                        </span>
                                        <span v-if="inter.status == 4" class="badge font-size-11 m-1 bg-success">
                                            Terminée
                                        </span>
                                    </td>
                                    <td>
                                        <button @click="$router.push({ path: `/interventionProgress/${inter.id}` })"
                                            class="btn btn-warning btn-sm me-2 w-xs">détails</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                  <div v-else class="loading-container">
                    <div class="text-center">
                      <i class="bx bx-loader bx-spin" style="font-size: 5em;"></i>
                      <h1>Chargement</h1>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <!-- end row -->
    </Layout>
</template>
<style>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh
}
</style>